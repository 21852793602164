import { Config } from './index';

const config: Config = {
  countryCode: 'no',
  defaultLocale: 'nb',
  currencySign: 'kr',
  checkoutUrl: 'https://bestill.freska.no',
  siteUrl: 'https://www.freska.no',
  appStoreUrl: 'https://itunes.apple.com/no/app/freska/id1397364064?mt=8',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=net.freska.freskaapp',
  googleReviewUrl: 'https://g.page/r/CS7owRf1_DkhEAI/review',
  termsAndConditionsUrl: {
    en: 'https://www.freska.no/en/terms-and-conditions',
    nb: 'https://www.freska.no/avtalevilkar',
  },
  privacyPolicyUrl: {
    en: 'https://www.freska.no/en/privacy-policy',
    nb: 'https://www.freska.no/personvernerklaering',
  },
  freskaAppUrl: {
    en: 'https://www.freska.no/en/freska-app',
    nb: 'https://www.freska.no/freska-appen',
  },
};

export default config;
